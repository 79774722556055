export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="">
          <div className="col-md-6">
            {" "}
            <img src="img/sobremi2.jpg" className="img-responsive" alt="Sobre la propuesta" />{" "}
          </div>
          <div className="col-md-6">
            <div className="about-text">
              <h2>Sobre la propuesta</h2>
              <p>{props.data ? props.data.paragraph : "loading..."} <br /> {props.data ? props.data.paragraph2 : "loading..."}</p>
              <h2 className="sobremi">Sobre mi</h2>
              <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              <h4>Más formal y en detalle:</h4>
              <p className="about-formal">{props.data ? props.data.paragraph4 : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
