import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='col section-title'>
          <h2>Artículos</h2>
          <p>
            Les comparto algunas reseñas que escribí para la web española <spam className="portfolio-bold">HA!</spam>
          </p>
        </div>
        <div id='row'>
          <div className='portfolio-items'>
            {props.data
                  ? props.data.map((d, i) => (
                      <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                          <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                      </div>        
                  ))
                  : 'Loading...'}
            </div>
        </div>
      </div>
    </div>
  )
}
